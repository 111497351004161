import React from 'react';
import config  from '../../config';

const IconLogo = () => (
  <svg width="84" height="96" viewBox="0 0 84 96" xmlns="http://www.w3.org/2000/svg">
    <title>Main Logo</title>
    <g clipPath="url(#clip0_101_2)">
      <path d="M42 3L3 25V70L42 93L81 71V26L42 3Z" fill={config.colors.navy} stroke="currentColor" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M42.9058 62.72C47.1592 62.72 49.2858 60.905 49.2858 57.275C49.2858 55.735 48.4792 54.4333 46.8658 53.37C45.6925 52.5633 44.0792 51.7017 42.0258 50.785C40.0092 49.8317 38.4875 49.08 37.4608 48.53C36.4708 47.98 35.3158 47.21 33.9958 46.22C31.4658 44.2767 30.2008 41.8567 30.2008 38.96C30.2008 36.0633 31.3192 33.6617 33.5558 31.755C35.8292 29.8117 38.8908 28.84 42.7408 28.84C46.5908 28.84 50.6975 30.0683 55.0608 32.525C55.0608 35.165 54.7858 38.025 54.2358 41.105C53.0625 41.4717 51.3208 41.655 49.0108 41.655L47.4158 35.11C46.0592 34.7067 44.5742 34.505 42.9608 34.505C41.3475 34.505 40.1008 34.8533 39.2208 35.55C38.3408 36.2467 37.9008 37.2917 37.9008 38.685C37.9008 40.0417 38.5792 41.27 39.9358 42.37C41.3292 43.4333 42.9975 44.4233 44.9408 45.34C46.8842 46.22 48.2225 46.8433 48.9558 47.21C49.6892 47.5767 50.6242 48.1267 51.7608 48.86C52.8975 49.5933 53.7592 50.29 54.3458 50.95C55.8492 52.71 56.6008 54.6717 56.6008 56.835C56.6008 60.6483 55.3358 63.5817 52.8058 65.635C50.2758 67.6883 46.9758 68.715 42.9058 68.715C40.7425 68.715 38.3592 68.3483 35.7558 67.615C33.1892 66.845 31.0075 65.8733 29.2108 64.7C29.2842 62.4633 29.5775 59.4383 30.0908 55.625C31.5575 55.3317 33.3175 55.185 35.3708 55.185L36.5258 61.785C38.5425 62.4083 40.6692 62.72 42.9058 62.72Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0_101_2">
        <rect width="84" height="96" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconLogo;
