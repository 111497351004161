import React from 'react';

const IconLoader = () => (
  <svg
    id="logo"
    width="84"
    height="96"
    viewBox="0 0 84 96"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <title>Loader Logo</title>
    <g clipPath="url(#clip0_1_2)">
        <path
        d="M42.17 60.72C46.4233 60.72 48.55 58.905 48.55 55.275C48.55 53.735 47.7433 52.4333 46.13 51.37C44.9567 50.5633 43.3433 49.7017 41.29 48.785C39.2733 47.8317 37.7517 47.08 36.725 46.53C35.735 45.98 34.58 45.21 33.26 44.22C30.73 42.2767 29.465 39.8567 29.465 36.96C29.465 34.0633 30.5833 31.6617 32.82 29.755C35.0933 27.8117 38.155 26.84 42.005 26.84C45.855 26.84 49.9617 28.0683 54.325 30.525C54.325 33.165 54.05 36.025 53.5 39.105C52.3267 39.4717 50.585 39.655 48.275 39.655L46.68 33.11C45.3233 32.7067 43.8383 32.505 42.225 32.505C40.6117 32.505 39.365 32.8533 38.485 33.55C37.605 34.2467 37.165 35.2917 37.165 36.685C37.165 38.0417 37.8433 39.27 39.2 40.37C40.5933 41.4333 42.2617 42.4233 44.205 43.34C46.1483 44.22 47.4867 44.8433 48.22 45.21C48.9533 45.5767 49.8883 46.1267 51.025 46.86C52.1617 47.5933 53.0233 48.29 53.61 48.95C55.1133 50.71 55.865 52.6717 55.865 54.835C55.865 58.6483 54.6 61.5817 52.07 63.635C49.54 65.6883 46.24 66.715 42.17 66.715C40.0067 66.715 37.6233 66.3483 35.02 65.615C32.4533 64.845 30.2717 63.8733 28.475 62.7C28.5483 60.4633 28.8417 57.4383 29.355 53.625C30.8217 53.3317 32.5817 53.185 34.635 53.185L35.79 59.785C37.8067 60.4083 39.9333 60.72 42.17 60.72Z"
          fill="currentColor"
        />
      <path
        d="M42 3L3 25V70L42 93L81 71V26L42 3Z"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="84" height="96" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconLoader;
